import { isEmpty } from "lodash";
import React, { useState } from "react";
import styled, { ThemeProvider } from "styled-components";

import ID_TYPES from "../../constants/ID_TYPES";
import hashids from "../../helpers/hashids";
import {
  ButtonText,
  Container,
  ConvertButton,
  Form,
  Result,
  Title,
  theme,
} from "../StyledComponents";

const onlyNumberRegex = /^[1-9]\d{0,20}$/;

const TypeSelect = styled.select`
  width: 175px;
  height: 44px;
  font-family: "Work Sans";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: ${(props) => props.theme.color.almostblack};
  padding-left: 10px;
  padding-right: 34px;
  appearance: none;
  background: url("arrow-down.svg") no-repeat 95% 50%;
  border: solid 1px #c7c7c7;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-right: none;
  &:focus {
    outline: none;
  }
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
  option:not(:checked) {
    color: black;
  }
`;

const TypeSelectNoSideBorders = styled(TypeSelect)`
  width: auto;
  border-right: none;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;

const CoreIDInput = styled.input`
  padding-left: 10px;
  width: ${(props) => props.width};
  height: 40px;
  border-style: solid;
  border-width: 1px;
  border-color: #c7c7c7;
  border-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  font-family: "Work Sans";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: ${(props) => props.theme.color.almostblack};
  margin-right: 8px;
  border-left: none;

  &:focus {
    outline: 0;
  }
`;

const ToSmartID = () => {
  const [selectedValue, setSelectedValue] = useState("acct");
  const [inputValue, setInputValue] = useState("");
  const [convertedValue, setConvertedValue] = useState("");
  const [version, setVersion] = useState<number>(1);

  function onSelectChange(e: React.FormEvent) {
    setSelectedValue((e.target as HTMLInputElement).value);
  }
  function onInputChange(e: React.FormEvent) {
    setInputValue((e.target as HTMLInputElement).value);
  }
  function convert() {
    if (!onlyNumberRegex.test(inputValue)) {
      setConvertedValue(
        "You enter the wrong format of the ID. Please check again."
      );
      return false;
    }

    const coreID = hashids.encode([version, BigInt(inputValue)]);
    setConvertedValue(`smartID: ${selectedValue}_${coreID}`);
  }
  function onVersionChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setVersion(parseInt(e.target.value));
  }
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Title>{"ID -> SmartID"}</Title>
        <Form>
          <TypeSelect onChange={onVersionChange}>
            <option key={1} value={1}>
              {"Core"}
            </option>
            <option key={2} value={2}>
              {"Plus"}
            </option>
            <option key={3} value={3}>
              {"Gift Card"}
            </option>
          </TypeSelect>
          <TypeSelectNoSideBorders onChange={onSelectChange}>
          {ID_TYPES.map(({ value, name }) => (
              <option key={value} value={value}>
                {`${name}`}
              </option>
            ))}
          </TypeSelectNoSideBorders>
          <CoreIDInput
            type="text"
            placeholder="ID"
            width="207px"
            value={inputValue}
            onChange={onInputChange}
          />
          <ConvertButton onClick={convert} disabled={isEmpty(inputValue)}>
            <ButtonText>{"Convert"}</ButtonText>
          </ConvertButton>
          <Result>{convertedValue}</Result>
        </Form>
      </Container>
    </ThemeProvider>
  );
};

export default ToSmartID;
